import { isNotBlank, ScrollToTop, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import '@shopify/polaris/build/esm/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import APIProvider from './APIProvider';
import App from './App';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `canva-frontend@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://9aca4bc7cd8341d0a2614b9378a61e58@o155661.ingest.sentry.io/4505088116260864',
		integrations: defaults => [...defaults, Sentry.browserTracingIntegration()],
		tracesSampleRate: 1,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: Infinity
		}
	}
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<QueryClientProvider client={queryClient}>
				<Router>
					<ScrollToTop>
						<Routes>
							<Route path="/*" element={<App />} />
						</Routes>
					</ScrollToTop>
					<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} />
				</Router>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</APIProvider>
	</StrictMode>
);
